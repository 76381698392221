import React from "react"
import { Link } from "gatsby"

const NotFound = () => {
    return (
        <>
            <h1>Page introuvable</h1>
            <p>
                <Link to="/">Retourner à l'accueil</Link>
            </p>
        </>
    )
}

export default NotFound